import { createRouter, createWebHistory } from 'vue-router';

import Swal from 'sweetalert2';
import i18n from '@/api/i18n';

// components
import MainLayout from '@/views/MainLayout.vue';
import HomeView from '@/views/HomeView.vue';
import DiagnosisBatteryTest from '@/views/DiagnosisBatteryTest.vue';
import DiagnosisCheckCable from '@/views/DiagnosisCheckCable.vue';
import Users from '@/views/UsersList.vue';
import UserEdit from '@/views/UserEdit.vue';
import UserCreate from '@/views/UserCreate.vue';
import EmailBlacklist from '@/views/EmailBlacklist.vue';
import SigninView from '@/views/SigninView.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import UserVerification from '@/views/UserVerification.vue';
import ResetPasswordVerification from '@/views/ResetPasswordVerification.vue';
import VerifyUesr from '@/views/VerifyUesr.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import ChangePassword from '@/views/ChangePassword.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';
import UserBatchCreate from '@/views/UserBatchCreate.vue';
import TestReport from '@/views/TestReport.vue';
import CableDiagnosis from '@/views/CableDiagnosis.vue';

import store from '@/store/index.js';

const t = i18n.global.t;

const routes = [
    {
        path: '/',
        name: 'signin',
        component: SigninView,
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
    },
    {
        path: '/verify-user-token/:token',
        name: 'verify-user-token',
        component: UserVerification,
        props: true,
    },
    {
        path: '/verify-user/:token',
        name: 'verify-user',
        component: VerifyUesr,
        props: true,
    },
    {
        path: '/verify-reset-password-token/:token',
        name: 'verify-reset-password-token',
        component: ResetPasswordVerification,
        props: true,
    },
    {
        path: '/reset-password/:token',
        name: 'reset-password',
        component: ResetPassword,
        props: true,
    },
    {
        path: '/dashboard',
        component: MainLayout,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'home',
                name: 'Home',
                component: HomeView,
            },
        ],
    },
    {
        path: '/users',
        name: 'Users',
        component: MainLayout,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'list',
                name: 'List all users',
                component: Users,
            },
            {
                path: 'create',
                name: 'Create a user',
                component: UserCreate,
            },
            {
                path: 'create-employee',
                name: 'Create an employee user',
                component: UserCreate,
                props: { userAccountLevel: 'Employee' },
                key: 'create-employee',
            },
            {
                path: 'create-business',
                name: 'Create a business user',
                component: UserCreate,
                props: { userAccountLevel: 'Business' },
                key: 'create-business',
            },
            {
                path: 'edit/:id',
                name: 'Edit user',
                component: UserEdit,
            },
            {
                path: 'change-password',
                name: 'Change password',
                component: ChangePassword,
            },
            {
                path: 'batch-create',
                name: 'Batch create users',
                component: UserBatchCreate,
            }
        ],
    },
    {
        path: '/diagnosis',
        name: 'Diagnosis',
        component: MainLayout,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'battery-test',
                name: 'Battery Test',
                component: DiagnosisBatteryTest,
            },
            {
                path: 'check-cable',
                name: 'Check Cable',
                component: DiagnosisCheckCable,
            },
        ],
    },
    {
        path: '/configs',
        name: 'Configs',
        component: MainLayout,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'email-blacklist',
                name: 'Email blacklist',
                component: EmailBlacklist,
            },
        ],
    },
    {
        path: '/sign-in/change-password',
        name: 'Signin and change password',
        component: ChangePassword,
        props: { formTitle: 'login.login_msg__account_default_password' },
    },
    {
        path: '/privacy-policy',
        name: 'Privacy Policy',
        component: PrivacyPolicy,
        meta: { requiresAuth: false },
    },
    {
        path: '/test-report/:uuid/:integrationKey/:clubId',
        name: 'Test Report',
        component: TestReport,
        meta: { requiresAuth: false },
        props: true,
    },
    {
        path: '/cable-diagnosis/:uuid/:integrationKey/:clubId',
        name: 'Cable Diagnosis',
        component: CableDiagnosis,
        meta: { requiresAuth: false },
        props: true,
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters['auth/isAuthenticated']) {
            Swal.fire({
                title: t('django.system_msg__status_code_401'),
                text: 'You need to sign in to access this page',
                icon: 'warning',
                confirmButtonText: 'OK',
            }).then(result => {
                if (result.isConfirmed) {
                    next({
                        path: '/',
                    });
                }
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
