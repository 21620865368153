import apiClient from '@/api/index';
import { setLoading } from '@/plugins/loading';

export const fetchBatteryTestReportResultFromAPI = async params => {
    try {
        const response = await apiClient.get('/diagnosis/battery-test', {
            params,
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const exportBatteryTestFromAPI = async (params, type) => {
    try {
        setLoading(true);
        if (localStorage.getItem('locale') !== undefined) {
            params.output_language = localStorage.getItem('locale');
        }
        const response = await apiClient.get(
            `/diagnosis/battery-test/export/${type}`,
            {
                params,
                responseType: 'blob',
            }
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchConfig = async () => {
    try {
        setLoading(true);
        const response = await apiClient.get('/diagnosis/battery-test/configs');
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    } finally {
        setLoading(false);
    }
};

export const getBatteryTestReportByUUID = async (params) => {
    try {
        setLoading(true);
        const url = `/diagnosis/battery-test/test-report?uuid=${params.uuid}&integration_key=${params.integration_key}&club_id=${params.club_id}`;
        const response = await apiClient.get(url, {
            skipAuthorization: true,
        });
        return response.data;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
    finally {
        setLoading(false);
    }
}
