<template>
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">
                {{ titleText }}
            </h4>
        </div>
        <form class="card-body me-3 ms-3" @submit.prevent="handleSubmit">
            <div v-for="(inputGroup, index) in formConfig" :key="index">
                <!-- 單一物件的情況 -->
                <div v-if="!Array.isArray(inputGroup)" class="form-group-row">
                    <UserFormGroupInput
                        ref="inputRefs"
                        v-bind="inputGroup"
                        v-model="formData[inputGroup.field]"
                        @valid="handleValidation"
                    />
                </div>
                <!-- 多個物件（數組）的情況 -->
                <div v-else class="form-group-row d-flex">
                    <div
                        v-for="(inputConfig, subIndex) in inputGroup"
                        :key="subIndex"
                        class="flex-fill"
                    >
                        <UserFormGroupInput
                            ref="inputRefs"
                            v-bind="inputConfig"
                            v-model="formData[inputConfig.field]"
                            @valid="handleValidation"
                        />
                    </div>
                </div>
            </div>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="submitDisabled"
                >
                    {{ $t('users.users_msg__user_form_submit') }}
                </button>
                <button
                    type="reset"
                    class="btn btn-secondary"
                    @click="handleReset"
                >
                    {{ $t('users.users_msg__create_account_reset') }}
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { ref, onMounted, inject, computed, watch } from 'vue';
import { UserFormGroupInput } from '@/components';
import { useI18n } from 'vue-i18n';
import {
    fetchCreateUserConfig,
    checkClubExist,
    createClub,
    createUser,
} from '@/api/users';
import { useRouter } from 'vue-router';

export default {
    name: 'UserCreate',
    components: {
        UserFormGroupInput,
    },
    props: {
        userAccountLevel: {
            type: String,
            default: 'Account',
            required: false,
        },
    },
    setup(props) {
        const formConfig = ref([]);
        const formData = ref({});
        const submitDisabled = ref(true);
        const { t } = useI18n();
        const router = useRouter();
        const Swal = inject('$swal');
        const inputRefs = ref([]);

        const titleText = computed(() => {
            if (props.userAccountLevel === 'Employee') {
                return t('sidebar.sidebar_msg__create_a_employee_user');
            } else if (props.userAccountLevel === 'Business') {
                return t('sidebar.sidebar_msg__create_a_business_user');
            } else {
                return t('sidebar.sidebar_msg__create_a_user');
            }
        });

        const initialize = async () => {
            try {
                formConfig.value = await fetchCreateUserConfig(
                    props.userAccountLevel
                );

                formConfig.value.forEach(config => {
                    if (Array.isArray(config)) {
                        config.forEach(subConfig => {
                            formData.value[subConfig.field] =
                                subConfig.value || '';
                        });
                    } else {
                        formData.value[config.field] = config.value || '';
                    }
                });
            } catch (error) {
                console.error('Error fetching form configuration:', error);
            }
        };

        onMounted(async () => {
            initialize();
        });

        watch(
            () => props.userAccountLevel,
            async () => {
                initialize();
            }
        );

        const handleValidation = isValid => {
            submitDisabled.value = !isValid;
        };

        const validateAll = () => {
            inputRefs.value.forEach(ref => {
                ref.checkValue();
            });
        };

        const handleSubmit = async () => {
            validateAll();

            const handleKeydown = event => {
                if (event.key === 'Enter') {
                    router.push('/users/list?sortColumn=cdate&sortOrder=desc');
                    Swal.close();
                } else if (event.key === 'Escape') {
                    location.reload();
                    Swal.close();
                }
            };

            if (submitDisabled.value) {
                return;
            }

            Swal.fire({
                title: 'Creating User',
                text: 'Please wait...',
                showConfirmButton: false,
                willOpen: () => {
                    Swal.showLoading();
                },
            });

            const clubNameExist = await checkClubExist(
                formData.value.club_name
            );
            if (!clubNameExist) {
                // create a new club
                try {
                    await createClub(formData.value.club_name);
                } catch (error) {
                    console.error('Error creating club:', error);
                }
            }
            // create a new user
            const userData = {
                ...formData.value,
            };
            Swal.close();
            try {
                await createUser(userData);
                await Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: t('users.users_msg__create_a_new_user_successful', {
                        username: userData.username,
                    }),
                    showConfirmButton: true,
                    showDenyButton: true,
                    confirmButtonText: t(
                        'users.users_msg__create_another_user'
                    ),
                    denyButtonText: t('users.users_msg__go_to_user_list'),
                    stopKeydownPropagation: false, // 允许键盘事件传播
                    didOpen: () => {
                        window.addEventListener('keydown', handleKeydown);
                    },
                    willClose: () => {
                        window.removeEventListener('keydown', handleKeydown);
                    },
                    didClose: () => {
                        location.reload();
                    },
                }).then(result => {
                    if (result.isConfirmed) {
                        // reload the window
                        location.reload();
                    } else if (result.isDenied) {
                        router.push(
                            '/users/list?sortColumn=cdate&sortOrder=desc'
                        );
                    }
                });
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: t(`users.${error.response.data.detail}`),
                });
            }
        };

        const handleReset = () => {
            formConfig.value.forEach(config => {
                if (Array.isArray(config)) {
                    config.forEach(subConfig => {
                        formData.value[subConfig.field] = subConfig.value || '';
                    });
                } else {
                    formData.value[config.field] = config.value || '';
                }
            });
        };

        return {
            titleText,
            formConfig,
            formData,
            submitDisabled,
            handleSubmit,
            handleValidation,
            handleReset,
        };
    },
};
</script>

<style scoped>
.card {
    box-shadow: none;
}
.form-group-row {
    margin-bottom: 1rem;
    text-align: left;
}

.d-flex {
    display: flex;
    gap: 1rem;
}

.flex-fill {
    flex: 1;
}
</style>
