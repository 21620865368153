<template>
    <div class="text-center body-linear">
        <div v-if="!error">
            <div class="top-linear">
                <div>CABLE Diagnosis</div>
            </div>
            <div class="body">
                <div class="uuid-bar" v-show="showUuidBar">
                    <span>UUID: </span><span>{{ testData.uuid }}</span>
                </div>
                <div class="uuid-bar" v-show="showUuidBar">
                    TIME:<span>{{ testData.test_time }}</span>
                </div>
                <div id="check-cable-info" v-show="showCheckCable">
                    <ul class="list">
                        <li>
                            <div class="result">Positive:</div>
                        </li>
                        <li>
                            <div
                                :style="{
                                    color: cableResultColor(
                                        testData.positive_pole_testing_result
                                    ),
                                }"
                            >
                                {{ testData.positive_pole_testing_result }}
                            </div>
                        </li>
                        <li>
                            <div class="result">Negative:</div>
                        </li>
                        <li>
                            <div
                                :style="{
                                    color: cableResultColor(
                                        testData.negative_pole_testing_result
                                    ),
                                }"
                            >
                                {{ testData.negative_pole_testing_result }}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div v-else class="error">
            <h2 class="red">ERROR</h2>
            <h2>Access Denied</h2>
        </div>
    </div>
</template>

<script>
import { reactive, computed, onMounted, ref } from 'vue';
import { getCheckCableReportByUUID } from '@/api/diagnosis/check-cable';

export default {
    props: {
        uuid: {
            Type: String,
            required: true,
        },
        integrationKey: {
            Type: String,
            required: true,
        },
        clubId: {
            Type: String,
            required: true,
        },
    },
    setup(props) {
        const testData = reactive({
            positive_pole_testing_result: '',
            negative_pole_testing_result: '',
            uuid: '',
            test_time: '',
            voltage: 0,
            current: 0,
        });

        const error = ref(false);
        const showUuidBar = ref(false);
        const showCheckCable = ref(false);

        onMounted(async () => {
            try {
                const param = {
                    uuid: props.uuid,
                    integration_key: props.integrationKey,
                    club_id: props.clubId,
                };
                const response = await getCheckCableReportByUUID(param);

                if (!response) {
                    throw new Error('No data');
                }

                Object.assign(testData, response);
                showUuidBar.value = true;
                showCheckCable.value = true;
            } catch (err) {
                error.value = true;
            }
        });

        function cableResultColor(result) {
            return (
                {
                    BAD: '#CC0000',
                    CAUTION: '#FF9900',
                    GOOD: '#00d400',
                }[result] || ''
            );
        }

        return {
            testData,
            error,
            showUuidBar,
            showCheckCable,
            cableResultColor,
        };
    },
};
</script>

<style scoped>
@import '@/assets/css/test-report.css';
</style>
