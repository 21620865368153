<template>
    <div class="text-center body-linear">
        <div v-if="!error">
            <div class="top-linear">
                <div>
                    Call ID: <span>{{ testData.call_id }}</span>
                </div>
                <div>
                    TIME: <span>{{ testData.test_time }}</span>
                </div>
            </div>
            <div class="body">
                <div class="uuid-bar" v-show="showUuidBar">
                    <span>UUID: </span><span>{{ testData.uuid }}</span>
                </div>
                <div id="bt-info" v-show="showBatteryTest">
                    <div class="title">BATTERY TEST</div>
                    <div
                        class="result"
                        :style="{ color: btResultColor(testData.result) }"
                    >
                        {{ testData.result }}
                    </div>
                    <div class="intr">
                        <div>{{ testData.btComm }}</div>
                        <div>{{ testData.btTp }}</div>
                    </div>
                    <ul class="list">
                        <li>
                            <div>State of Charge:</div>
                            <div>{{ testData.soc }} %</div>
                        </li>
                        <li>
                            <div>State of Health:</div>
                            <div>{{ testData.soh }} %</div>
                        </li>
                        <li>
                            <div>Voltage:</div>
                            <div>{{ testData.voltage }} V</div>
                        </li>
                        <li>
                            <div>Battery Type:</div>
                            <div>{{ testData.battery_type }}</div>
                        </li>
                        <li>
                            <div>Set Capacity:</div>
                            <div>
                                {{ testData.set_capacity }}
                                {{ testData.rating }}
                            </div>
                        </li>
                        <li>
                            <div>Measure Capacity:</div>
                            <div>
                                {{ testData.measured_capacity }}
                                {{ testData.rating }}
                            </div>
                        </li>
                    </ul>
                </div>
                <div id="st-info" v-show="showSystemTest">
                    <div class="title">SYSTEM TEST</div>
                    <ul class="list">
                        <li>
                            <div>{{ testData.cranking_voltage }} V</div>
                            <div>CRANKING VOLTS:</div>
                            <div
                                :style="{
                                    color: resultColorV1(
                                        testData.cranking_voltage_judgement
                                    ),
                                }"
                            >
                                {{ testData.cranking_voltage_judgement }}
                            </div>
                        </li>
                        <li>
                            <div>{{ testData.alt_idle_voltage }} V</div>
                            <div>ALT.IDLE VOLTS:</div>
                            <div
                                :style="{
                                    color: resultColorV2(
                                        testData.alt_idle_voltage_judgement
                                    ),
                                }"
                            >
                                {{ testData.alt_idle_voltage_judgement }}
                            </div>
                        </li>
                        <li>
                            <div>{{ testData.alt_load_voltage }} V</div>
                            <div>ALT.LOAD VOLTS:</div>
                            <div
                                :style="{
                                    color: resultColorV2(
                                        testData.alt_load_voltage_judgement
                                    ),
                                }"
                            >
                                {{ testData.alt_load_voltage_judgement }}
                            </div>
                        </li>
                        <li>
                            <div>{{ testData.ripple_voltage }} V</div>
                            <div>DIODE RIPPLE:</div>
                            <div
                                :style="{
                                    color: resultColorV1(
                                        testData.ripple_voltage_judgement
                                    ),
                                }"
                            >
                                {{ testData.ripple_voltage_judgement }}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div v-else class="error">
            <h2 class="red">ERROR</h2>
            <h2>Access Denied</h2>
        </div>
    </div>
</template>

<script>
import { reactive, computed, onMounted, ref } from 'vue';
import { getBatteryTestReportByUUID } from '@/api/diagnosis/battery-test';

export default {
    props: {
        uuid: {
            Type: String,
            required: true,
        },
        integrationKey: {
            Type: String,
            required: true,
        },
        clubId: {
            Type: String,
            required: true,
        },
    },
    setup(props) {
        const testData = reactive({
            call_id: '',
            test_time: '',
            uuid: '',
            soc: '',
            soh: '',
            measured_voltage: '',
            battery_type: '',
            set_capacity: '',
            measured_capacity: '',
            cranking_voltage: '',
            cranking_voltage_judgement: '',
            alt_idle_voltage: '',
            alt_idle_voltage_judgement: '',
            alt_load_voltage: '',
            alt_load_voltage_judgement: '',
            ripple_voltage: '',
            ripple_voltage_judgement: '',
            result: '',
            rating: '',
            test_type: '',
            btComm: '',
            btTp: '',
        });

        const error = ref(false);
        const showUuidBar = ref(false);
        const showBatteryTest = ref(false);
        const showSystemTest = ref(false);

        onMounted(async () => {
            try {
                const param = {
                    uuid: props.uuid,
                    integration_key: props.integrationKey,
                    club_id: props.clubId,
                };
                const response = await getBatteryTestReportByUUID(param);

                if (!response) {
                    throw new Error('No data');
                }

                Object.assign(testData, response);
                testTypeLayout(testData.test_type);
                btResultMap(testData.result);
                showUuidBar.value = true;
            } catch (err) {
                error.value = true;
            }
        });

        function testTypeLayout(type) {
            if (type === 'BATTERY TEST') showBatteryTest.value = true;
            if (type === 'SYSTEM TEST') showSystemTest.value = true;
            if (type === 'COMBINATION TEST') {
                showBatteryTest.value = true;
                showSystemTest.value = true;
            }
        }

        function btResultMap(state) {
            const messages = {
                'GOOD & PASS': [
                    'The battery has useful life remaining.',
                    'Meets or exceeds the industry requirements. Continue to use as designed.',
                ],
                'GOOD & RECHARGE': [
                    'The battery is not fully charged but is considered to have useful life remaining.',
                    'Charge before returning to service.',
                ],
                'BAD & REPLACE': [
                    'This battery has reached its end of life. Immediate replacement is advised.',
                    'Battery fails to meet industry standards.',
                ],
                CAUTION: [
                    'The battery may be serviceable but with decreased capability to start the engine.',
                    'Please monitor battery performance.',
                ],
            };
            [testData.btComm, testData.btTp] = messages[state] || ['', ''];
        }

        function resultColorV1(result) {
            return (
                {
                    'NO DETECTED': '#CC0000',
                    NORMAL: '#00d400',
                    LOW: '#FF9900',
                    HIGH: '#FF9900',
                }[result] || ''
            );
        }

        function resultColorV2(result) {
            return (
                {
                    'NO DETECTED': '#CC0000',
                    LOW: '#CC0000',
                    NORMAL: '#00d400',
                }[result] || ''
            );
        }

        function btResultColor(result) {
            return (
                {
                    'BAD CELL REPLACE': '#CC0000',
                    'BAD & REPLACE': '#CC0000',
                    CAUTION: '#CC0000',
                    'GOOD & PASS': '#00d400',
                    'GOOD & RECHARGE': '#00d400',
                    'RECHARGE & RETEST': '#FF9900',
                }[result] || ''
            );
        }

        return {
            testData,
            error,
            showUuidBar,
            showBatteryTest,
            showSystemTest,
            resultColorV1,
            resultColorV2,
            btResultColor,
        };
    },
};
</script>

<style scoped>
@import '@/assets/css/test-report.css';
</style>
