<template>
    <div class="card" style="box-shadow: none">
        <form class="card-body me-3 ms-3" @submit.prevent="handleSubmit">
            <div v-for="(inputGroup, index) in formConfig" :key="index">
                <!-- 單一物件的情況 -->
                <div v-if="!Array.isArray(inputGroup)" class="form-group-row">
                    <UserFormGroupInput
                        ref="inputRefs"
                        v-bind="inputGroup"
                        v-model="formData[inputGroup.field]"
                        @valid="handleValidation"
                    />
                </div>
                <!-- 多個物件（數組）的情況 -->
                <div v-else class="form-group-row d-flex">
                    <div
                        v-for="(inputConfig, subIndex) in inputGroup"
                        :key="subIndex"
                        class="flex-fill"
                    >
                        <UserFormGroupInput
                            ref="inputRefs"
                            v-bind="inputConfig"
                            v-model="formData[inputConfig.field]"
                            @valid="handleValidation"
                        />
                    </div>
                </div>
            </div>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="submitDisabled"
                >
                    {{ $t('users.users_msg__user_form_submit') }}
                </button>
                <button
                    type="button"
                    class="btn btn-secondary"
                    @click="handleReset"
                >
                    {{ $t('users.users_msg__create_account_reset') }}
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { ref, onMounted, inject, watch } from 'vue';
import { UserFormGroupInput } from '@/components';
import { useI18n } from 'vue-i18n';
import {
    fetchEditUserConfig,
    checkClubExist,
    createClub,
    editUser,
    getUser,
} from '@/api/users';

export default {
    name: 'UserEdit',
    components: {
        UserFormGroupInput,
    },
    props: {
        user: {
            type: Array,
            required: true,
        },
    },
    setup(props, { emit }) {
        const formConfig = ref([]);
        const formData = ref({});
        const submitDisabled = ref(false);
        const { t } = useI18n();
        const Swal = inject('$swal');

        const user = ref({});
        const inputRefs = ref([]);

        const accountLevel = ref('Account');

        const initialize = async () => {
            await loadUserDetail();
            await loadFormConfig();
            initializeFormData();
        };

        onMounted(async () => {
            await initialize();
        });

        const loadFormConfig = async () => {
            try {
                formConfig.value = await fetchEditUserConfig(accountLevel.value);
            } catch (error) {
                console.error('Error fetching form configuration:', error);
            }
        };

        const loadUserDetail = async () => {
            try {
                user.value = await getUser(props.user[0]);
                accountLevel.value = user.value.groups[0];
            } catch (error) {
                console.error('Error fetching user detail:', error);
                if (error.response && error.response.status === 404) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: t('users.users_msg__user_not_found'),
                    }).then(() => {
                        // Close the modal
                        emit('resetModal');
                    });
                }
            }
        };

        const initializeFormData = () => {
            formConfig.value.forEach(config => {
                if (Array.isArray(config)) {
                    config.forEach(subConfig => {
                        if (Array.isArray(user.value[subConfig.field])) {
                            formData.value[subConfig.field] =
                                user.value[subConfig.field][0] || '';
                            subConfig.value =
                                user.value[subConfig.field][0] || '';
                        } else {
                            formData.value[subConfig.field] =
                                user.value[subConfig.field] || '';
                            subConfig.value = user.value[subConfig.field] || '';
                        }
                    });
                } else {
                    if (Array.isArray(user.value[config.field])) {
                        formData.value[config.field] =
                            user.value[config.field].join(', ') || '';
                        config.value =
                            user.value[config.field].join(', ') || '';
                    } else {
                        formData.value[config.field] =
                            user.value[config.field] || '';
                        config.value = user.value[config.field] || '';
                    }
                }
            });
        };

        // 監控 user prop 的變化，更新表單數據
        watch(() => props.user, initializeFormData);

        const handleValidation = isValid => {
            submitDisabled.value = !isValid;
        };

        const handleSubmit = async () => {
            inputRefs.value.forEach(ref => {
                ref.checkValue();
            });
            if (submitDisabled.value) {
                return;
            }
            Swal.fire({
                title: t('users.users_msg__editing_user'),
                text: t('users.users_msg__please_wait'),
                showConfirmButton: false,
                willOpen: () => {
                    Swal.showLoading();
                },
            });
            const clubNameExist = await checkClubExist(
                formData.value.club_name
            );
            if (!clubNameExist) {
                // create a new club
                try {
                    await createClub(formData.value.club_name);
                } catch (error) {
                    console.error('Error creating club:', error);
                }
            }
            // create a new user
            let userData = formConfig.value.map(item => {
                if (Array.isArray(item)) {
                    return item.map(subItem => {
                        if (
                            subItem.type === 'readonly' ||
                            subItem.value === formData.value[subItem.field]
                        ) {
                            return {};
                        } else {
                            return {
                                [subItem.field]: formData.value[subItem.field],
                            };
                        }
                    });
                } else {
                    if (
                        item.type === 'readonly' ||
                        item.value === formData.value[item.field]
                    ) {
                        return {};
                    } else {
                        return {
                            [item.field]: formData.value[item.field],
                        };
                    }
                }
            });

            // remove empty objects in userData
            userData = userData.filter(item => Object.keys(item).length !== 0);

            userData = Object.assign({}, ...userData);

            Swal.close();

            if (Object.keys(userData).length === 0) {
                Swal.fire({
                    icon: 'info',
                    title: t('users.users_msg__no_changes_have_been_made'),
                });
                return;
            }

            try {
                await editUser(props.user[0], userData);
                await Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: t('users.users_msg__edit_a_new_user_successful', {
                        username: userData.username,
                    }),
                });
                emit('submitSuccess');
                Swal.close();
            } catch (error) {
                await Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: t(`users.${error.response.data.detail}`),
                });
            }
        };

        const handleReset = async () => {
            await initialize();
        };

        return {
            formConfig,
            formData,
            submitDisabled,
            handleSubmit,
            handleValidation,
            handleReset,
            inputRefs,
        };
    },
};
</script>

<style scoped>
.form-group-row {
    margin-bottom: 1rem;
    text-align: left;
}

.d-flex {
    display: flex;
    gap: 1rem;
}

.flex-fill {
    flex: 1;
}
</style>
